<template>
  <div v-if="!loading">
    <v-layout v-if="!missingAttributes[0]" row wrap align-center py-3>
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="green">info</v-icon></v-flex
      >
      <v-flex xs11
        ><strong>All group general attributes created</strong></v-flex
      >
    </v-layout>
    <v-layout
      v-if="missingAttributes && missingAttributes[0]"
      row
      wrap
      align-center
      py-3
      style="background:rgba(0,0,0,0.05)"
    >
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="red">info</v-icon></v-flex
      >
      <v-flex xs11><strong>Missing attributes:</strong></v-flex>
      <v-flex xs12 mb-3></v-flex>
      <v-flex xs1> </v-flex>
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex
            xs12
            mb-2
            class="red--text"
            v-for="(attribute, i) in missingAttributes"
            :key="`attribute-${i}`"
            >{{ attribute.name }}</v-flex
          >
          <v-flex xs12>
            <v-btn
              round
              class="mx-0 primary text-none"
              @click="createAttributes"
              :disabled="processing"
              >Create</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {
  categories as requiredAttributeCategories,
  attributes as requiredAttributes,
} from "@/components/AppStatusPage/manifests/general-attributes.json";

export default {
  data: () => ({
    loading: false,
    processing: false,
    perPage: 500,
    attributes: [],
    attributeCategories: [],
  }),
  computed: {
    attributeSlugs() {
      return this.attributes.map((el) => el.slug);
    },
    missingAttributes() {
      return requiredAttributes.filter(
        (el) => !this.attributeSlugs.includes(el.slug),
      );
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async createAttributes() {
      this.processing = true;

      for await (const attribute of this.missingAttributes) {
        const attributeCategory = this.attributeCategories.find(
          (el) => el.slug === attribute.category,
        );

        if (attribute.category && !attributeCategory) {
          const requiredAttributeCategory = requiredAttributeCategories.find(
            (el) => el.slug === attribute.category,
          );

          const res1 = await this.$api.groupGeneralAttributeCategories.create(
            requiredAttributeCategory,
          );

          this.attributeCategories.push(res1.data.data);

          const res2 = await this.$api.groupGeneralAttributes.create({
            categories: res1.data.data.id,
            ...attribute,
          });

          this.attributes.push(res2.data.data);
        } else {
          const res = await this.$api.groupGeneralAttributes.create({
            categories: attributeCategory ? attributeCategory.id : null,
            ...attribute,
          });

          this.attributes.push(res.data.data);
        }
      }

      this.processing = false;
    },
    async fetchData() {
      this.loading = true;

      const [res1, res2] = await Promise.all([
        this.listAttributeCategories(),
        this.listAttributes(),
      ]);

      if (res1 && res2) {
        this.loading = false;

        this.attributeCategories = res1.data.data;
        this.attributes = res2.data.data;
      }
    },
    listAttributeCategories() {
      const params = { per_page: this.perPage };
      return this.$api.groupGeneralAttributeCategories.list(params);
    },
    listAttributes() {
      const params = { per_page: this.perPage };
      return this.$api.groupGeneralAttributes.list(params);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
