import { render, staticRenderFns } from "./PluginGeneralAttributes.vue?vue&type=template&id=7384b506&scoped=true&"
import script from "./PluginGeneralAttributes.vue?vue&type=script&lang=js&"
export * from "./PluginGeneralAttributes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7384b506",
  null
  
)

export default component.exports