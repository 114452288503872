<template>
  <div v-if="!loading">
    <v-layout v-if="!showMissingPluginAttributes" row wrap align-center py-3>
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="green">info</v-icon></v-flex
      >
      <v-flex xs11
        ><strong>All plugin general attributes created</strong></v-flex
      >
    </v-layout>
    <v-layout
      v-if="showMissingPluginAttributes"
      row
      wrap
      align-center
      py-3
      style="background:rgba(0,0,0,0.05)"
    >
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="red">info</v-icon></v-flex
      >
      <v-flex xs11><strong>Missing plugin general attributes:</strong></v-flex>
      <v-flex xs12 mb-3></v-flex>
      <v-flex xs1> </v-flex>
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex
            xs12
            mb-2
            class="red--text"
            v-for="(attribute, i) in missingPluginAttributes()"
            :key="`plugin-attribute-${i}`"
          >
            {{ attribute.name }}
          </v-flex>
          <v-flex xs12>
            <v-btn
              round
              class="mx-0 primary text-none"
              @click="createPluginAttributes"
              :disabled="processing"
              >Create</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { attributes } from "@/components/AppStatusPage/manifests/plugin-general-attributes.json";

export default {
  data: () => ({
    loading: false,
    processing: false,
    perPage: 500,
    pluginAttributes: [],
  }),
  computed: {
    showMissingPluginAttributes() {
      return this.missingPluginAttributes() &&
        this.missingPluginAttributes().length
        ? true
        : false;
    },
  },
  mounted() {
    this.listPluginAttributes();
  },
  methods: {
    createPluginAttributes() {
      this.processing = true;

      const missingPluginAttributes = this.missingPluginAttributes();

      const specs = missingPluginAttributes.map((el) => ({
        name: el.name,
        slug: el.slug,
        type: el.type,
      }));

      this.$api.groupPluginAttributes
        .create(...specs)
        .then((response) => {
          if (!response) return;
          this.listPluginAttributes();
        })
        .catch((error) => {
          this.errorMessageShow(error);
        })
        .finally(() => (this.processing = false));
    },
    listPluginAttributes() {
      this.loading = true;

      const params = {
        per_page: this.perPage,
      };

      this.$api.groupPluginAttributes
        .list(params)
        .then((response) => {
          if (!response) return;
          this.pluginAttributes = response.data.data;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    missingPluginAttributes() {
      if (!this.pluginAttributes || !this.pluginAttributes.length) {
        return [];
      }

      const pluginAttributeSlugs = this.pluginAttributes.map((el) => el.slug);

      return attributes.filter((el) => !pluginAttributeSlugs.includes(el.slug));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
