<template>
  <div v-if="!loading">
    <v-layout v-if="!missingSettings[0]" row wrap align-center py-3>
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="green">info</v-icon></v-flex
      >
      <v-flex xs11><strong>All settings created</strong></v-flex>
    </v-layout>

    <v-layout
      v-if="missingSettings && missingSettings[0]"
      row
      wrap
      align-center
      py-3
      style="background:rgba(0,0,0,0.05)"
    >
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="red">info</v-icon></v-flex
      >
      <v-flex xs11><strong>Missing settings:</strong></v-flex>
      <v-flex xs12 mb-3></v-flex>

      <v-flex xs1> </v-flex>
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex
            xs12
            mb-2
            class="red--text"
            v-for="(setting, i) in missingSettings"
            :key="`setting-${i}`"
            >{{ setting.key }}</v-flex
          >
          <v-flex xs12>
            <v-btn
              round
              class="mx-0 primary text-none"
              @click="createSettings"
              :disabled="processing"
              >Create</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import _ from "lodash";
import { settings } from "@/components/AppStatusPage/manifests/settings.json";

export default {
  data: () => ({
    loading: false,
    processing: false,
    perPage: 500,
    appSettings: [],
  }),
  computed: {
    existingSettings() {
      return this.appSettings.map((el) => el.key);
    },
    missingSettings() {
      return settings.filter((el) => !this.existingSettings.includes(el.key));
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.loading = true;

      this.$api.settings.list({ per_page: this.perPage }).then((res) => {
        this.loading = false;

        if (res.data.data && res.data.data[0]) {
          this.appSettings = res.data.data;
        }
      });
    },
    async createSettings() {
      this.processing = true;

      for await (const setting of this.missingSettings) {
        const res = await this.$api.settings.create(setting);

        this.appSettings.push(res.data.data);
      }

      this.processing = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
