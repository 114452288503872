<template>
  <v-layout
    :style="{ background: 'rgba(0,0,0,0.05)' }"
    class="row wrap align-center py-3"
  >
    <v-flex class="xs1 text-xs-center pl-4"
      ><v-icon color="warning">info</v-icon></v-flex
    >
    <v-flex class="xs11"><strong>Group policies</strong></v-flex>
    <v-flex class="xs12"></v-flex>
    <v-flex class="xs1"></v-flex>
    <v-flex class="xs11">
      <v-layout row wrap>
        <v-flex class="xs12 mb-2">Here you can set up default policies</v-flex>
        <v-flex v-if="roles.length && policies.length" class="xs12">
          <v-btn
            @click="updatePolicies"
            :loading="loading"
            round
            class="mx-0 primary text-none"
            >Set up policies</v-btn
          >
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import policyTemplates from "./manifests/policy-templates.json";

export default {
  data: () => ({
    perPage: 1000,
    roles: [],
    policies: [],
    pendingRequests: 0,
  }),
  computed: {
    loading() {
      return this.pendingRequests ? true : false;
    },
  },
  mounted() {
    this.listRoles();
    this.listPolicies();
  },
  methods: {
    listRoles() {
      const params = { per_page: this.perPage };

      this.$api.groupRoles.list(params).then((response) => {
        if (!response) return;
        this.roles = response.data.data;
      });
    },
    listPolicies() {
      const params = { per_page: this.perPage };

      this.$api.groupPolicies.list(params).then((response) => {
        if (!response) return;
        this.policies = response.data.data;
      });
    },
    async updatePolicies() {
      if (this.pendingRequests !== 0) return;

      for await (const template of policyTemplates) {
        const policy = this.policies.find((el) => el.name === template.name);

        if (!policy || !policy.id) continue;

        const roles = [];

        for (const role of template.group_roles) {
          const roleObj = this.roles.find((el) => el.name === role);

          if (roleObj) {
            roles.push(roleObj);
          }
        }

        const specs = [
          policy.id,
          { group_roles: roles.map((el) => el.id).toString() },
        ];

        this.pendingRequests++;

        this.$api.groupPolicies.update(...specs).then(
          () => {
            this.pendingRequests--;
          },
          () => {
            this.pendingRequests--;
          },
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
