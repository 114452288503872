<template>
  <v-container class="general">
    <PageTitle :title="'App status'" />
    <v-card>
      <v-layout row wrap>
        <v-flex xs12>
          <Settings />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <GeneralAttributes />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <PluginAttributes />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <ArticleGeneralAttributes />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <PluginGeneralAttributes />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <UserAttributes />
        </v-flex>
        <v-flex xs12><v-divider></v-divider></v-flex>
        <v-flex xs12>
          <GroupPolicies />
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import Settings from "@/components/AppStatusPage/Settings.vue";
import GeneralAttributes from "@/components/AppStatusPage/GeneralAttributes.vue";
import PluginAttributes from "@/components/AppStatusPage/PluginAttributes.vue";
import ArticleGeneralAttributes from "@/components/AppStatusPage/ArticleGeneralAttributes.vue";
import PluginGeneralAttributes from "@/components/AppStatusPage/PluginGeneralAttributes.vue";
import UserAttributes from "@/components/AppStatusPage/UserAttributes.vue";
import GroupPolicies from "@/components/AppStatusPage/GroupPolicies.vue";

export default {
  components: {
    Settings,
    GeneralAttributes,
    PluginAttributes,
    ArticleGeneralAttributes,
    PluginGeneralAttributes,
    UserAttributes,
    GroupPolicies,
  },
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
