<template>
  <div v-if="!loading">
    <v-layout v-if="!missingAttributes[0]" row wrap align-center py-3>
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="green">info</v-icon></v-flex
      >
      <v-flex xs11><strong>All user attributes created</strong></v-flex>
    </v-layout>

    <v-layout
      v-if="missingAttributes && missingAttributes[0]"
      row
      wrap
      align-center
      py-3
      style="background:rgba(0,0,0,0.05)"
    >
      <v-flex xs1 class="text-xs-center pl-4"
        ><v-icon color="red">info</v-icon></v-flex
      >
      <v-flex xs11><strong>Missing user attributes:</strong></v-flex>
      <v-flex xs12 mb-3></v-flex>

      <v-flex xs1> </v-flex>
      <v-flex xs11>
        <v-layout row wrap>
          <v-flex
            xs12
            mb-2
            class="red--text"
            v-for="(attribute, i) in missingAttributes"
            :key="`attribute-${i}`"
            >{{ attribute.name }}</v-flex
          >
          <v-flex xs12>
            <v-btn
              round
              class="mx-0 primary text-none"
              @click="createAttributes"
              :disabled="processing"
              >Create</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import _ from "lodash";
import { attributes } from "@/components/AppStatusPage/manifests/user-attributes.json";

export default {
  data: () => ({
    loading: false,
    processing: false,
    perPage: 500,
    attributes: [],
  }),
  computed: {
    existingAttributes() {
      return this.attributes.map((el) => el.slug);
    },
    missingAttributes() {
      return attributes.filter(
        (el) => !this.existingAttributes.includes(el.slug),
      );
    },
  },
  mounted() {
    this.listAttributes();
  },
  methods: {
    listAttributes() {
      this.loading = true;

      this.$api.userAttributes.list({ per_page: this.perPage }).then((res) => {
        this.loading = false;

        if (res.data.data && res.data.data[0]) {
          this.attributes = res.data.data;
        }
      });
    },
    async createAttributes() {
      this.processing = true;

      for await (const attribute of this.missingAttributes) {
        const res = await this.$api.userAttributes.create(attribute);

        this.attributes.push(res.data.data);
      }

      this.processing = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
