<template>
  <div class="plugin-attributes">
    <template v-if="!isLoading">
      <v-layout
        v-if="!missingPluginAttributes || !missingPluginAttributes.length"
        row
        wrap
        class="py-3 align-center"
      >
        <v-flex xs1 class="pl-4 text-center">
          <v-icon class="green--text">info</v-icon>
        </v-flex>
        <v-flex xs11>
          <strong>All group plugin attributes created</strong>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="missingPluginAttributes && missingPluginAttributes.length"
        row
        wrap
        style="background:rgba(0,0,0,0.05)"
        class="py-3"
      >
        <v-flex xs12 class="mb-2">
          <v-layout row wrap class="align-center">
            <v-flex xs1 class="pl-4 text-xs-center">
              <v-icon class="red--text">info</v-icon>
            </v-flex>
            <v-flex xs11>
              <strong>Missing group plugin attributes:</strong>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap class="justify-end">
            <v-flex
              v-for="(pluginAttribute, i) in missingPluginAttributes"
              :key="`plugin-attribute-${i}`"
              xs11
              class="mb-2 red--text"
            >
              {{ pluginAttribute.name }}
            </v-flex>
            <v-flex xs11>
              <v-btn
                round
                class="mx-0 primary text-none"
                :loading="isProcessing"
                @click="createGroupPluginAttributes"
                >Create</v-btn
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
import { plugin_attributes } from "@/components/AppStatusPage/manifests/plugin-attributes.json";

export default {
  data: () => ({
    isLoading: false,
    isProcessing: false,
    pluginAttributes: [],
  }),
  computed: {
    pluginAttributeSlugs() {
      return this.pluginAttributes.map((el) => el.slug);
    },
    missingPluginAttributes() {
      return plugin_attributes.filter(
        (el) => !this.pluginAttributeSlugs.includes(el.slug),
      );
    },
  },
  mounted() {
    this.listGroupPluginAttributes();
  },
  methods: {
    listGroupPluginAttributes() {
      this.isLoading = true;

      const params = { per_page: 1000 };

      this.$api.groupPluginAttributes.list(params).then(
        (response) => {
          this.isLoading = false;

          if (!response.data || !response.data.data) {
            return;
          }

          this.pluginAttributes = response.data.data;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async createGroupPluginAttributes() {
      this.isProcessing = true;

      try {
        for await (const attribute of this.missingPluginAttributes) {
          const specs = {
            name: attribute.name,
            slug: attribute.slug,
            type: attribute.type,
            prefix: attribute.prefix,
          };

          const response = await this.$api.groupPluginAttributes.create(specs);

          if (!response.data || !response.data.data) {
            this.isProcessing = false;
            return;
          }

          this.pluginAttributes.push(response.data.data);
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
      }
    },
  },
};
</script>
